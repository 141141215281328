const initialState = {
  deviceEui:"",
  appEui: '0000000000000000',
  appPort: "002",
  joinType:"1",
  networkSessionKey: '00000000000000000000000000000000',
  applicationSessionKey: '00000000000000000000000000000000',
  applicationKey:'20000000000000000000000000000001',
  rx2DataRate: '02',
  rx2Frequency: '',
  spreadingFactor: '02',
  txPower: '00',
  workMode:"0",
  region:"0",
  rxChannel:0,
  reportingInterval:"0001",
  offsetTemp:"-05.0",
  offsetHumidity:"+10",
  offsetCo2:"+0000",
  status:false,
  co2:"0000",
  humidity:"000",
  temp:"00.0",
  deviceType:"02",
  adrmode:1,
  battery:"00",
  confirmed_mode:1,
  confirmed_mode_retries:8,
  data_storage:0,
  data_retransmission:0,
  model_number:"AQM02",
  firm_ver_number:"1.0.0.0",
  lora_ver_number:"1.0.0.0",
  rssi:"-60",
  sync_timestamp:"1717599888",
  reading_timestamp:"1717599888",
  ssid: "",
  password: "",
  pm1Offset: "+00",
  pm2_5Offset: "+00",
  pm10Offset: "+00",
  pressureOffset: "+00",
  coOffset: "+00.000",
  coRo: "0400000",
  no2Offset: "+00.000",
  no2Ro: "0002200",
  o3Offset: "+00.000",
  tvocOffset: "+00.000",
  organizationId: "",
  nh3Offset: "+00.000",
  nh3Ro: "0600000",
  noiseOffset: "+00",
  luxOffset: "+000",
  occupancyOffset: "0000000000",
  pressure: "",
  pm1: "",
  pm2_5: "",
  pm10: "",
  tvoc: "",
  no2: "",
  co: "",
  o3: "",
  nh3: "",
  audioDba: "",
  audioPeakDba: "",
  occupancyStatus: "",
  light: "",
  espFwVersion: "",
  communicationMode: "01",
  peopleCount:"",
  co2Calibration:"01",
  co2CalibrationValue:"0400",
  calibrationStatus:"0",
};

const resetReducer = (state = initialState, action) => {
  switch (action.type) {

     case 'UPDATE_RESET_all':
      return { ...state,...action.payload };        
    case 'UPDATE_RESET_deviceEui':
      return { ...state, deviceEui: action.payload };
    case 'UPDATE_RESET_appEui':
      return { ...state, appEui: action.payload };
    case 'UPDATE_RESET_appPort':
      return { ...state, appPort: action.payload };
    case 'UPDATE_RESET_joinType':
      return { ...state, joinType: action.payload };
    case 'UPDATE_RESET_networkSessionKey':
      return { ...state, networkSessionKey: action.payload };
    case 'UPDATE_RESET_applicationSessionKey':
      return { ...state, applicationSessionKey: action.payload };
    case 'UPDATE_RESET_applicationKey':
      return { ...state, applicationKey: action.payload };
    case 'UPDATE_RESET_deviceAddress':
      return { ...state, deviceAddress: action.payload };
    case 'UPDATE_RESET_rx2DataRate':
      return { ...state, rx2DataRate: action.payload };
    case 'UPDATE_RESET_rx2Frequency':
      return { ...state, rx2Frequency: action.payload };
    case 'UPDATE_RESET_spreadingFactor':
      return { ...state, spreadingFactor: action.payload };
    case 'UPDATE_RESET_txPower':
      return { ...state, txPower: action.payload };
    case 'UPDATE_RESET_workMode':
      return { ...state, workMode: action.payload };
    case 'UPDATE_RESET_region':
      return { ...state, region: action.payload };
    case 'UPDATE_RESET_rxChannel':
      return { ...state, rxChannel: action.payload };
    case 'UPDATE_RESET_reportingInterval':
      return { ...state, reportingInterval: action.payload };
    case 'UPDATE_RESET_offsetTemp':
      return { ...state, offsetTemp: action.payload };
    case 'UPDATE_RESET_offsetHumidity':
      return { ...state, offsetHumidity: action.payload };
    case 'UPDATE_RESET_offsetCo2':
      return { ...state, offsetCo2: action.payload };

    case 'UPDATE_RESET_status':
      return { ...state, status: action.payload };
  case 'UPDATE_RESET_co2':
      return { ...state, co2: action.payload };
    case 'UPDATE_RESET_humidity':
        return { ...state,humidity:action.payload };
    case 'UPDATE_RESET_temp':
        return { ...state,temp:action.payload };        
    case 'UPDATE_RESET_deviceType':
        return { ...state,deviceType:action.payload };        
    case 'UPDATE_RESET_battery':
        return { ...state,battery:action.payload };            
    case 'UPDATE_RESET_adrmode':
        return { ...state,adrmode:action.payload };         
    case 'UPDATE_RESET_confirmed_mode':
          return { ...state,confirmed_mode:action.payload };   
    case 'UPDATE_RESET_confirmed_mode_retries':
            return { ...state,confirmed_mode_retries:action.payload };   
    case 'UPDATE_RESET_data_storage':
            return { ...state,data_storage:action.payload };   
    case 'UPDATE_RESET_data_retransmission':
            return { ...state,data_retransmission:action.payload };   
    case 'UPDATE_RESET_model_number':
            return { ...state,model_number:action.payload };   
    case 'UPDATE_RESET_version_number':
        return { ...state,version_number:action.payload };   
    case 'UPDATE_RESET_rssi':
          return { ...state,rssi:action.payload };   
    case 'UPDATE_RESET_sync_timestamp':
        return { ...state,sync_timestamp:action.payload };   
    case 'UPDATE_RESET_reading_timestamp':
        return { ...state,reading_timestamp:action.payload };       

    case 'UPDATE_RESET_firm_ver_number':
        return { ...state,firm_ver_number:action.payload };   
    case 'UPDATE_RESET_lora_ver_number':
        return { ...state,lora_ver_number:action.payload };    

         case 'UPDATE_RESET_ssid':
      return { ...state, ssid: action.payload };
    case 'UPDATE_RESET_password':
      return { ...state, password: action.payload };
    case 'UPDATE_RESET_pm1Offset':
      return { ...state, pm1Offset: action.payload };
    case 'UPDATE_RESET_pm2_5Offset':
      return { ...state, pm2_5Offset: action.payload };
    case 'UPDATE_RESET_pm10Offset':
      return { ...state, pm10Offset: action.payload };
    case 'UPDATE_RESET_pressureOffset':
      return { ...state, pressureOffset: action.payload };
    case 'UPDATE_RESET_coOffset':
      return { ...state, coOffset: action.payload };
    case 'UPDATE_RESET_coRo':
      return { ...state, coRo: action.payload };
    case 'UPDATE_RESET_no2Offset':
      return { ...state, no2Offset: action.payload };
    case 'UPDATE_RESET_no2Ro':
      return { ...state, no2Ro: action.payload };
    case 'UPDATE_RESET_o3Offset':
      return { ...state, o3Offset: action.payload };
    case 'UPDATE_RESET_tvocOffset':
      return { ...state, tvocOffset: action.payload };
    case 'UPDATE_RESET_organizationId':
      return { ...state, organizationId: action.payload };
    case 'UPDATE_RESET_nh3Offset':
      return { ...state, nh3Offset: action.payload };
    case 'UPDATE_RESET_nh3Ro':
      return { ...state, nh3Ro: action.payload };
    case 'UPDATE_RESET_noiseOffset':
      return { ...state, noiseOffset: action.payload };
    case 'UPDATE_RESET_luxOffset':
      return { ...state, luxOffset: action.payload };
    case 'UPDATE_RESET_occupancyOffset':
      return { ...state, occupancyOffset: action.payload };
    case 'UPDATE_RESET_pressure':
      return { ...state, pressure: action.payload };
    case 'UPDATE_RESET_pm1':
      return { ...state, pm1: action.payload };
    case 'UPDATE_RESET_pm2_5':
      return { ...state, pm2_5: action.payload };
    case 'UPDATE_RESET_pm10':
      return { ...state, pm10: action.payload };
    case 'UPDATE_RESET_tvoc':
      return { ...state, tvoc: action.payload };
    case 'UPDATE_RESET_no2':
      return { ...state, no2: action.payload };
    case 'UPDATE_RESET_co':
      return { ...state, co: action.payload };
    case 'UPDATE_RESET_o3':
      return { ...state, o3: action.payload };
    case 'UPDATE_RESET_nh3':
      return { ...state, nh3: action.payload };
    case 'UPDATE_RESET_audioDba':
      return { ...state, audioDba: action.payload };
    case 'UPDATE_RESET_audioPeakDba':
      return { ...state, audioPeakDba: action.payload };
    case 'UPDATE_RESET_occupancyStatus':
      return { ...state, occupancyStatus: action.payload };
    case 'UPDATE_RESET_light':
      return { ...state, light: action.payload };
    case 'UPDATE_RESET_espFwVersion':
      return { ...state, espFwVersion: action.payload };
    case 'UPDATE_RESET_communicationMode':
      return { ...state, communicationMode: action.payload };    
    case 'UPDATE_RESET_peopleCount':
      return { ...state, peopleCount: action.payload };    
    case 'UPDATE_RESET_co2Calibration':
      return { ...state, co2Calibration: action.payload };
    case 'UPDATE_RESET_co2CalibrationValue':
      return { ...state, co2CalibrationValue: action.payload };  
    case 'UPDATE_RESET_calibrationStatus':
      return { ...state, calibrationStatus: action.payload };
    default:
      return state;
  }
};

export default resetReducer;
