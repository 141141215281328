import React,{useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Collapse, Modal, Row,Col, Select } from 'antd';
import LorwanTab from './LorwanTab';
import GeneralTab from './GeneralTab';
import { PlusOutlined,MinusOutlined } from '@ant-design/icons';
import { ActionsContext } from '../../contexts/context';
import WriteLanding from  '../Scanner/Writer/index'
import { Input, Form } from 'antd';
import { useNavigate } from "react-router-dom";
import CalibrationTab from "./CalibrationTab";

const { Panel } = Collapse;

function Reset() {
  const {Option} = Select;
  const navigate=useNavigate()
  const lorwanState = useSelector((state) => state.reset);
  const settingState = useSelector((state)=>state.lorwan)
  const dispatch = useDispatch();
  const [actions, setActions] = useState(null);
  const {scan, write} = actions || {};
  const [valueData,SetValueData] =useState({
    
  })
  useEffect(()=>{
    console.log("resetValues in reset pages",lorwanState);
  },[lorwanState])
  const actionsValue = {actions,setActions};
  const [nfcSettingData,SetNfCSetting] = useState({});
  const [nfcScan,SetNFcScan]= useState([])
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [secondModalVisible, setSecondModalVisible] = useState(false);
  const [finalValue,SetFinalValue] = useState();
  const [title,SetTitle] =useState();
  const [thirdModal,SetThirdModal]= useState(false);
  const [modalTitle,SetModalTitle]= useState(false);
  const [error,SetError]= useState({});
  const [resetModal,SetResetModal]= useState(false);
  const savedTemplate = localStorage.getItem('nfcTemplate')?JSON.parse(localStorage.getItem('nfcTemplate')):[]
  const [listTemplate,SetListTemplate] = useState([
    ...savedTemplate,{id:100000,name:"Custom",type:"default",value:"custom"}
  ]);

  const [errorModal,SetErrorModal]= useState(false);

  
  const [selectTemplate,setTemplateSelected] = useState('')

  const handleLorwanFieldChange = (field, value) => {
    dispatch({ type: `UPDATE_RESET_${field.toUpperCase()}`, payload: value });
  };
 let sequnece = [
    { id: 1, key: "deviceEui", required: false, validation: "hex", length: 16 },
    { id: 2, key: "appEui", required: false, validation: "hex", length: 32 },
    { id: 3, key: "applicationKey", required: true, validation: "hex", length: 32 },
    { id: 4, key: "appPort", required: true, validation: "number", length: 3 },
    { id: 5, key: "joinType", required: true, validation: "number", length: 1 },
    { id: 6, key: "networkSessionKey", required: false, validation: "hex", length: 32 },
    { id: 7, key: "applicationSessionKey", required: false, validation: "hex", length: 32 },
    { id: 8, key: "workMode", required: true, validation: "number", length: 1 },
    { id: 9, key: "region", required: true, validation: "number", length: 1 },
    { id: 10, key: "rx2DataRate", required: true, validation: "number", length: 2 },
    { id: 11, key: "rxChannel", required: false, validation: "number", length: 2 },
    { id: 12, key: "spreadingFactor", required: true, validation: "number", length: 2 },
    { id: 13, key: "txPower", required: true, validation: "number", length: 2 },
    { id: 14, key: "adrmode", required: true, validation: "number", length: 1 },
    { id: 15, key: "reportingInterval", required: true, validation: "number", length: 4 },
    { id: 16, key: "confirmed_mode", required: true, validation: "number", length: 1 },
    { id:  17, key: "confirmed_mode_retries", required: true, validation: "number", length: 1 },
    { id:  18, key: "data_storage", required: true, validation: "number", length: 1 },
    { id:  19, key: "data_retransmission", required: true, validation: "number", length: 1 },
    { id: 20, key: "offsetTemp", required: true, validation: "numberString", length: 5 },
    { id: 21, key: "offsetHumidity", required: true, validation: "numberString", length: 3 },
    { id: 22, key: "offsetCo2", required: true, validation: "numberString", length: 5 },
    { id: 23, key: "model_number", required: false, validation: "string", length: 5 },
    { id: 24, key: "firm_ver_number", required: false, validation: "string", length: 5 },
    { id: 25, key: "lora_ver_number", required: false, validation: "string", length: 5 },
    { id: 26, key: "rssi", required: false, validation: "numberString", length: 5 },
    { id: 27, key: "sync_timestamp", required: false, validation: "number", length: 10 },
    { id: 28, key: "reading_timestamp", required: false, validation: "number", length: 10 },
    { id: 29, key: "battery", required: false, validation: "numberString", length: 2 },
    { id: 30, key: "temp", required: false, validation: "numberString", length: 5 },
    { id: 31, key: "humidity", required: false, validation: "numberString", length: 5 },
    { id: 32, key: "co2", required: false, validation: "numberString", length: 5 },
  ]

    useEffect(() => {
    // Set up the message event listener to handle messages from the native side
    const handleMessage = (event) => {
    
      
      const message = event.data;
      
      if (message && message.type === 'nfcWriteSuccess') {
        handleWriteNFC()
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
   const handleSuccessClose = () => {
    setSuccessModalVisible(false);
  };

  const handleSave = () => {
    let previousItem = localStorage.getItem('nfcTemplate')?JSON.parse(localStorage.getItem('nfcTemplate')):[]
    previousItem.push({type:"",name:title,value:finalValue,id:previousItem.length +1})
    localStorage.setItem('nfcTemplate',JSON.stringify(previousItem));
    SetListTemplate((prev)=>[...prev,{type:"",name:title,value:finalValue,id:previousItem.length +1}]);
    SetModalTitle(false);
    setSecondModalVisible(true);
    
  };


   const onHandleAction = (actions) =>{
    setActions({...actions});
  }

  function validation(obj,value){
    //console.log("validation",obj,value);
    let length = obj.length;
    let type= obj.validation;
    let field = obj.key;
    let hex = new RegExp(`^[0-9A-Fa-f]{${length}}$`); 
    let no = new RegExp(`^\\d{${length}}$`); 
    let noString = new RegExp(`^-?\\d{${length}}$`); 

     if(!obj.required){
      return true
    }
    else if ((type === "hex" && hex.test(value) && value.length <= length)  ) {
      return true
    } else if ((type === "number" && !isNaN(value) && value!=="" && value.toString().length <= length )) {
      return true
    }else if(field ==='rxChannel' && value!=="" && value.toString().length <= length){
      return true
    } else if ((type === "numberString" && (value.includes("+") || value.includes("-")) && value.length <= length) ) {
      return true
    }else{
      return false
    } 
    
  }

  function savedDataTemplate(typeOperation){
    SetTitle('');
    let gatewaySetting={};
    gatewaySetting=lorwanState;
    let value="";
    let seq=[];
    let errorCount=0;

    const now = new Date();
    const utcTimestamp = parseInt((now.getTime())/1000);


    //abp
  console.log("default value",lorwanState)
    // if(lorwanState.joinType =="0"){
      // seq = [...sequnece].filter(el=>el.key!="applicationKey")
    // }else if(lorwanState.joinType =="1"){
      // seq = [...sequnece].filter(el=>el.key!="networkSessionKey" && el.key!="applicationSessionKey")
    // }else{
      seq = [...sequnece]
    // }
    console.log("seq",seq,[...sequnece]);
    let er={}
    for(let i=0;i<seq.length;i++){
      console.log("validation",validation(seq[i],lorwanState[seq[i].key]))
      console.log("valid Field",seq[i].key,lorwanState[seq[i].key])    
      if(!validation(seq[i],lorwanState[seq[i].key])){
        er[seq[i].key] = "Field is required or invalid"
        errorCount++;
      }
      if(i===0){
        value+=seq[i].id+":"+settingState.deviceEui+","
      }
      else if(seq[i].key === "sync_timestamp" ){
        value+=seq[i].id+":"+utcTimestamp+",";
      }
      else if(seq[i].key === "co2"){
        value+=seq[i].id+":"+lorwanState[seq[i].key];
      }else{
        value+=seq[i].id+":"+lorwanState[seq[i].key]+",";
      }
      

    }
    SetError(er);
    console.log("value",value,er,errorCount);

// SetModalTitle(true);
    if(errorCount==0){
      SetFinalValue(value);
      SetNfCSetting(value);
      //handleWriteNFC(value)
      if(window.ReactNativeWebView){
        
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'writeNFC',data:value }));
      }
      
      onHandleAction({scan: null, write: 'scaning'});
    }else{
      SetErrorModal(true);
    }




  }

  function writeSucess(){
       onHandleAction({scan: null, write: null})       
  }


 const handleWriteNFC = async (value) => {
      setSecondModalVisible(true);
      setSuccessModalVisible(true);    
      onHandleAction({scan: null, write: null})       
};

const handleSecondModalClose = () => {
    setSecondModalVisible(false);
  };


  return (
    <div className="App">
      <div className="container">
           
       <Collapse accordion defaultActiveKey={"lorwan"}
        expandIcon={({ isActive }) => isActive?<MinusOutlined />:<PlusOutlined />}
        style={{marginTop:20}}
      >
        <Panel header="LoRaWAN" key="lorwan">
          <LorwanTab error={error} mode={'disabled'} />
        </Panel>
        <Panel header="General" key="general">
          <GeneralTab error={error} mode={'disabled'}/>
        </Panel>
        <Panel header="Calibration" key="calibration">
          <CalibrationTab error={error} mode={'disabled'}/>
        </Panel>
        
      </Collapse>
      
        <ActionsContext.Provider value={actionsValue}>
          {write && <WriteLanding message={nfcSettingData} writeSucess={writeSucess}/>}
        </ActionsContext.Provider>

        <div>
        </div>

    <div className="App-container-bottom " style={{position:"relative",padding:"15px 0px",marginBottom:15}}>       
          <button className="btn btn  btn-transparent"  onClick={()=>SetResetModal(true)}>Reset</button>
          {/* <button onClick={()=>savedDataTemplate()} style={{marginTop:"10px"}} className="btn btn-transparent">Write</button> */}
        </div>

    </div>

   <Modal
        title="Error"
        open={errorModal}
        onCancel={()=>SetErrorModal(false)}
        footer={[
          <Button key="close" onClick={()=>SetErrorModal(false)}>
            Close
          </Button>,
        ]}
      >
        <div className="errorSmaller">
        {Object.entries(error).map(([key, value]) => (
    <Row
      gutter={16}
      style={{ marginBottom: 5, paddingBottom: 5, borderBottom: "1px solid #ddd" }}
      key={key}
    >
      <Col span={8} style={{color:"#a22626",fontSize:10}}>{key}</Col>
      <Col span={16} style={{fontSize:10}}>{value}</Col>
    </Row>
  ))}
        </div>
      </Modal>


    <Modal
        title="Reset"
        open={resetModal}
        onCancel={()=>SetResetModal(false)}
        footer={[
          <Button key="close" onClick={()=>SetResetModal(false)}>
            No, Go back
          </Button>,
          <Button key="save" type="primary" onClick={()=>{SetResetModal(false);savedDataTemplate();}}>
            Yes, Reset
          </Button>,
        ]}
      >
        Are You sure wan't to reset
      </Modal>

        <Modal
        title="Save Template"
        open={modalTitle}
        onCancel={()=>SetModalTitle(false)}

        footer={[
          <Button key="close" onClick={()=>SetModalTitle(false)}>
            Close
          </Button>,
        ]}
      >
    <Form>
      
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item className='settingfield' label="Template Title">
            <Input
              value={title}
              onChange={(e) => SetTitle(e.target.value)}
            />
          </Form.Item>
        </Col>
        </Row>
        </Form>       
      </Modal>

    <Modal
        title="Reset Done"
        open={secondModalVisible}
        onCancel={()=>handleSecondModalClose()}
        footer={[
          <Button key="close" onClick={()=>handleSecondModalClose()}>
            Close
          </Button>,
        ]}
      >
        Device reset successfully
      </Modal>
   </div> 
  );
}

export default Reset;
