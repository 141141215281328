// App.js
import React,{useEffect, useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RouterComponent from './common/routes'; // Adjust the path accordingly
import TopHeader from './common/TopHeader'
import { ActionsContext } from './contexts/context';
import './assets/css/common.css';

import { getTemplate } from './components/SettingsAQM05/actionMethods';

function App() {
  const [actions, setActions] = useState(null);
  const actionsValue = { actions, setActions };
  let isLoggedin= localStorage.getItem('isLoggedinSafeAirConfig')?true:false;
let userDetails=  localStorage.getItem('userLoggedinSafeAirConfig')?JSON.parse(localStorage.getItem('userLoggedinSafeAirConfig')):{};
  useEffect(()=>{
      if(isLoggedin){
      getTemplate(userDetails.session,userDetails.org_id).then(res=>{
        if(res.status === 300){
          
          localStorage.removeItem('isLoggedinSafeAirConfig');  
          localStorage.removeItem('userLoggedinSafeAirConfig');  
          window.location.href = '/login'
        }
      })
      }

  },[isLoggedin])


  return (
    <div className="App">
     <ActionsContext.Provider value={actionsValue}>
        {isLoggedin?<TopHeader/>:""}
        <RouterComponent />  
    </ActionsContext.Provider>

    </div>
  );
}

export default App;
