// LorwanTab.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Switch, Form, Row, Col,InputNumber,Button,Select, Tooltip } from 'antd';
import { Alert, Space } from 'antd';
import {ReactComponent as Info} from '../../assets/images/circle-info-solid.svg'
const LorwanTab = (props) => {
  const lorwanState = useSelector((state) => state.lorwan);
  const {Option} = Select;
  const dispatch = useDispatch();
  console.log("props",props)
  const channel = ["923","915","470","779","433","868","865",	"920","915"]
  const port = ["001","002","003","004","005","006","007","008","009","010","011","012","013","014","015","016","017","018","019","020","021","022","023","024","025","026","027","028","029","030","031","032","033","034","035","036","037","038","039","040","041","042","043","044","045","046","047","048","049","050","051","052","053","054","055","056","057","058","059","060","061","062","063","064","065","066","067","068","069","070","071","072","073","074","075","076","077","078","079","080","081","082","083","084","085","086","087","088","089","090","091","092","093","094","095","096","097","098","099","100","101","102","103","104","105","106","107","108","109","110","111","112","113","114","115","116","117","118","119","120","121","122","123","124","125","126","127","128","129","130","131","132","133","134","135","136","137","138","139","140","141","142","143","144","145","146","147","148","149","150","151","152","153","154","155","156","157","158","159","160","161","162","163","164","165","166","167","168","169","170","171","172","173","174","175","176","177","178","179","180","181","182","183","184","185","186","187","188","189","190","191","192","193","194","195","196","197","198","199","200","201","202","203","204","205","206","207","208","209","210","211","212","213","214","215","216","217","218","219","220","221","222","223","224","225"]
  
  
  
  const handleFieldChange = (field, value, type, length) => {
    console.log("confirm",field, value, type, length)
    if (field === "region") {
      dispatch({ type: `UPDATE_LORWAN_rx2DataRate`, payload: "" });
      dispatch({ type: `UPDATE_LORWAN_rxChannel`, payload: "" });
      dispatch({ type: `UPDATE_LORWAN_spreadingFactor`, payload: "" });
    }


    let hex = new RegExp(`^[0-9A-Fa-f]{1,${length}}$`); // Dynamic length for hex
    let no = new RegExp(`^\\d{1,${length}}$`);  // Dynamic length for numbers
    let noString = new RegExp(`^-?\\d{1,${length}}$`);  // Dynamic length for noString
    console.log("Adr mode",type === "number" && no.test(value), value.length <= length , (value.length==0));


  console.log("values",field,value);
  if(field === "rx2DataRate"){
    handleFieldChange('spreadingFactor', value,'number',2)
  }

    if (field==="adrmode" || field==="confirmed_mode" || field==="data_storage" || field==="data_retransmission" || field==="confirmed_mode_retries"   ) {
      dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });


      if(field==="adrmode"  && parseInt(lorwanState.region)===0  && (lorwanState.spreadingFactor=== '01' || lorwanState.spreadingFactor=== '00' || lorwanState.spreadingFactor=== '06' || lorwanState.spreadingFactor=== '07' )){
         dispatch({ type: `UPDATE_LORWAN_spreadingFactor`, payload: '02' });

      }

      console.log("condition 1");
    }
    else if ((type === "hex" && hex.test(value) && value.length <= length) || (value.length==0) ) {
  console.log("condition 2");
      dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
    } else if ((type === "number" && no.test(value) && value.length <= length )|| (value.length==0)) {
      console.log("condition 3");
      dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
    }else if(field ==='rxChannel'){
      console.log("condition ");
      dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
    } else if ((type === "noString" && noString.test(value) && value.length <= length) || (value.length==0)) {
      console.log("condition 5");
      dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
    }
    console.log(field, value, type,no, (length,type === "number" && no.test(value) && value.length <= length )|| (value.length==0))
  };

 const handleIncrement = (field,val) => {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: Number(parseFloat(val) + 0.1).toFixed(1) });
  };

  const handleDecrement = (field,val) => {
        dispatch({ type: `UPDATE_LORWAN_${field}`, payload: Number(parseFloat(val) - 0.1).toFixed(1) });
  };



  const txPower={
    "0":[
      {id:"00",name:"TXPower0-16 dBm"},
      {id:"01",name:"TXPowerl-14 dBm"},
      {id:"02",name:"TXPower2-12 dBm"},
      {id:"03",name:"TXPower3-1 0 dBm"},
      {id:"04",name:"TXPower4-8 dBm"},
      {id:"05",name:"TXPower5-6 dBm" },
      {id:"06",name:"TXPower6-4 dBm"}, 
      {id:"07",name:"TXPower7-2 dBm"},
    ],
    "6":[
      {id:"00",name:"TXPowerO-22dBm"},
      {id:"01",name:"TXPowerl-22 dBm"},
      {id:"02",name:"TXPower2-22 dBm"},
      {id:"03",name:"TXPower3-22 dBm"},
      {id:"04",name:"TXPower4-22 dBm"},
      {id:"05",name:"TXPower5-20 dBm" },
      {id:"06",name:"TXPower6-18 dBm"}, 
      {id:"07",name:"TXPower7-16 dBm"},
      {id:"08",name:"TXPower8-14 dBm"},
      {id:"09",name:"TXPower9-12dBm"},
      {id:"10",name:"TXPower10-10 dBm"}
    ]
}






  const rx2Data= {
    "0":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
    "1":[
          {id:"00",spread:"SF10 - DR0",name:"SF10 - BW125" },
          {id:"01",spread:"SF9 - DR1",name:"SF9  - BW125" },
          {id:"02",spread:"SF8 - DR2",name:"SF8  - BW125" },
          {id:"03",spread:"SF7 - DR3",name:"SF7  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW500" },
          {id:"08",spread:"SF12 - DR8",name:"SF12 - BW500" },
          {id:"09",spread:"SF11 - DR9",name:"SF11 - BW500" },
          {id:"10",spread:"SF10 - DR10",name:"SF10 - BW500" },
          {id:"11",spread:"SF9 - DR11",name:"SF9 - BW500" },
          {id:"12",spread:"SF8 - DR12",name:"SF8 - BW500" },
          {id:"13",spread:"SF7 - DR13",name:"SF7 - BW500" },
          
        ],
    "2":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF12 - DR6",name:"SF12 - BW125" },
          {id:"07",spread:"SF12 - DR7",name:"SF12 - BW125" },
        ],
      "3":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "4":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "5":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "6":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "7":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9  - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8  - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7  - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7  - DR6",name:"SF7  - BW250" },
      ],
        "8":[
          {id:"00",spread:"SF10 - DR0",name:"SF10 - BW125"},
          {id:"01",spread:"SF9 - DR1",name:"SF9  - BW125"},
          {id:"02",spread:"SF8 - DR2",name:"SF8  - BW125"},
          {id:"03",spread:"SF7 - DR3",name:"SF7  - BW125"},
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW500"},
          {id:"08",spread:"SF12- DR8",name:"SF12 - BW500"},
          {id:"09",spread:"SF11- DR9",name:"SF11 - BW500"},
          {id:"10",spread:"SF10- DR10",name:"SF10 - BW500"},
          {id:"11",spread:"SF9 - DR11",name:"SF9 - BW500"},
          {id:"12",spread:"SF8 - DR12",name:"SF8 - BW500"},
          {id:"13",spread:"SF7 - DR13",name:"SF7 - BW500"},
        ],
  }

  return (
    <Form>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item className='settingfield' label="Device EUI">
            <Input
              value={lorwanState.deviceEui}
              onChange={(e) => handleFieldChange('deviceEui',e.target.value,'hex',16)}
              status={props.error && props.error['deviceEui']?"error":""}
              disabled


            />

          </Form.Item>
          
        </Col>

        <Col span={24}>
          <Form.Item className='settingfield' label="Device Type">
            {/* <Input
              value={lorwanState.appEui}
              onChange={(e) => handleFieldChange('appEui',e.target.value,'hex',16)}
              status={props.error && props.error['appEui']?"error":""}
              e
            /> */}

            <Select
              value={lorwanState.model_number}
              onChange={(e) => handleFieldChange('model_number', e,'',2)}
              showSearch
              status={props.error && props.error['model_number']?"error":""}
              disabled
            >
              <Option value="AQM01" key={"deviceType01"} disabled>SA-AQM01</Option> 
              <Option value="AQM02" key={"deviceType02"}>SA-AQM02</Option>
              <Option value="AQM03" key={"deviceType03"} disabled>SA-AQM03</Option>
              <Option value="AQM01" key={"deviceType04"} disabled>SA-AQM04</Option>
              <Option value="AQM05" key={"deviceType05"}>SA-AQM05</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item className='settingfield' label="App Port">
            
            <Select
              value={lorwanState.appPort}
              onChange={(e) => handleFieldChange('appPort', e,'number',3)}
              showSearch
               status={props.error && props.error['appPort']?"error":""}
               disabled={props.mode && props.mode=="disabled"?true:false}
            >
              {
                port.map(el=>{
                  return <Option value={el} key={"appPort"+el}>{parseInt(el)}</Option>
                })
              }

            </Select>

          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item className='settingfield' label="Join Type">
            <Select
              value={lorwanState.joinType}
              onChange={(e) => handleFieldChange('joinType', e,'number',1)}
              status={props.error && props.error['joinType']?"error":""}
              disabled={props.mode && props.mode=="disabled"?true:false}
            >
              <Option  value="0" key={"JoinType00"} disabled>ABP</Option>
              <Option  value="1" key={"JoinType01"}>OTAA</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
       {lorwanState.joinType=="0"?
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item className='settingfield' label="Network Session Key">
            <Input
              value={lorwanState.networkSessionKey}
              onChange={(e) => handleFieldChange('networkSessionKey', e.target.value,'hex',32)}
               status={props.error && props.error['networkSessionKey']?"error":""}
               disabled={props.mode && props.mode=="disabled"?true:false}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item className='settingfield' label="Application Session Key">
            <Input
              value={lorwanState.applicationSessionKey}
              onChange={(e) => handleFieldChange('applicationSessionKey', e.target.value,'hex',32)}
               status={props.error && props.error['applicationSessionKey']?"error":""}
               disabled={props.mode && props.mode=="disabled"?true:false}
            />
          </Form.Item>
        </Col>
          
      </Row>:lorwanState.joinType=="1"?
      <Row gutter={16}>
        
        <Col span={24}>
          <Form.Item className='settingfield' label="Application Key">
            <Input
              value={lorwanState.applicationKey}
              onChange={(e) => handleFieldChange('applicationKey', e.target.value,'hex',32)}
              status={props.error && props.error['applicationKey']?"error":""}
              disabled={props.mode && props.mode=="disabled"?true:false}
            />
          </Form.Item>
        </Col>
                

      </Row>:""      
      }       
 <Row gutter={16}>
  <Col span={24}>
          <Form.Item className='settingfield' label="Work Mode">
  
            <Select
            value={lorwanState.workMode}
              onChange={(e) => handleFieldChange('workMode', e,'number',1)}
               status={props.error && props.error['workMode']?"error":""}
               disabled={props.mode && props.mode=="disabled"?true:false}
            >
              <Option  value="0" key={"workMode0"}>Class A</Option>
              <Option  value="1" key={"workMode1"} disabled>Class B</Option>
              <Option  value="2" key={"workMode2"} disabled>Class C</Option>
            </Select>
          </Form.Item>
        </Col>
 </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item className='settingfield' label="Region">

            <Select
            value={lorwanState.region}
              onChange={(e) => handleFieldChange('region', e,'number',1)}
               status={props.error && props.error['region']?"error":""}
              //  disabled={props.mode && props.mode=="disabled"?true:false}
            >
              <Option  value="0" key={"region0"}>AS923</Option>
              <Option  value="1"  key={"region1"} disabled>AU915</Option>
              <Option  value="2"  key={"region2"} disabled>CN470</Option>
              <Option  value="3"  key={"region3"} disabled>CN799</Option>
              <Option  value="4"  key={"region4"} disabled>EU433</Option>
              <Option  value="5"  key={"region5"} disabled>EU868</Option>
              <Option  value="6"  key={"region6"}>IN865</Option>
             <Option  value="7"   key={"region7"}disabled>KR920</Option>
             <Option  value="8"   key={"region8"} disabled>US915</Option>

            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item className='settingfield' label="RX2 Data Rate">
          <Select
            value={lorwanState.rx2DataRate}
              onChange={(e) => handleFieldChange('rx2DataRate', e,'number',2)}
               status={props.error && props.error['rx2DataRate']?"error":""}
               disabled={props.mode && props.mode=="disabled"?true:false}
            >
              {lorwanState.region && rx2Data[lorwanState.region].map(el=>{
              
                  return <Option  value={el.id}  key={"rx2DataRate"+el} disabled={el.name.startsWith('FSK') || (['SF12 - BW125','SF11 - BW125','SF7  - BW250'].indexOf(el.name) > -1 && lorwanState.region=="0") }>{el.name}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
     <Row gutter={16} style={{marginTop:10}}>
        <Col span={20}><label>ADR Mode <Tooltip placement="topLeft" color={'#f4f4f4'} colorText={"#373434"} fontSize={10} lineHeight={1.2} title={"Enable this mode to optimise the data rate, airtime and energy consumption of uplink data, it's recommended to enable in stable RF conditions."} >
            <Info/>
          </Tooltip></label></Col>
        <Col span={4} style={{float:"right"}}>
          <Form.Item className='settingfield' label="">
            <Switch
              checked={lorwanState.adrmode==1?true:false}
              onChange={(e)=>handleFieldChange('adrmode', e?1:0,'number',1)}
            />
          </Form.Item>
        </Col>
    </Row>

      <Row gutter={16}>

        <Col span={24}>
          <Form.Item className='settingfield' label={<span>Spreading Factor
        <Tooltip placement="topLeft" color={'#f4f4f4'} colorText={"#373434"} title={<div>
	<p>The spreading factor controls the chirp rate, and thus controls the speed of data transmission.</p> 
	<p>Lower spreading factors mean faster chirps and therefore a higher data transmission rate</p>
</div>} >
            <Info/>
          </Tooltip>
 </span>       
        }>
             <Select
              value={lorwanState.spreadingFactor}
               status={props.error && props.error['spreadingFactor']?"error":""}
              onChange={(e) => handleFieldChange('spreadingFactor', e,'number',2)}
              // disabled={props.mode && props.mode=="disabled"?true:false}
              disabled={true}
            >
              {lorwanState.region && rx2Data[lorwanState.region].map(el=>{
                  return <Option  value={el.id} key={"region"+el.id} disabled={(parseInt(lorwanState.region) === 0) && ['SF12 - DR0','SF11 - DR1','SF7 - DR6','FSK - DR7'].indexOf(el.spread) > -1 ?true:el.spread && el.spread.startsWith('FSK')?true:false}>{el.spread}</Option>
              })}
            </Select> 



          </Form.Item>
        </Col>
                <Col span={24}>
          <Form.Item className='settingfield' label="Tx Power">
            
            <Select
              value={lorwanState.txPower}
              onChange={(e) => handleFieldChange('txPower', e,'number',2)}
               status={props.error && props.error['txPower']?"error":""}
               disabled={props.mode && props.mode=="disabled"?true:false}
            >
              
              {lorwanState.region && txPower[lorwanState.region].map(el=>{
                  return <Option  value={el.id} key={"power"+el.id}>{el.name}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

      </Row>





 

    {/* <Row gutter={16} className='br-btm-space'>
        <Col span={8}>
          <Form.Item className='settingfield' label="">
            <Switch
              checked={lorwanState.channelMode2Switch}
              onChange={(checked) => handleFieldChange('channelMode2Switch', checked)}
            />
          </Form.Item>
        </Col>
          <Col span={16}>
 <Form.Item className='settingfield' label="">

      <Row gutter={16}>
      <Col span={6}> 
            <Button onClick={()=>handleIncrement('channelMode2Value',lorwanState.channelMode2Value)} className='btnChannel'>+</Button>
          </Col>
          <Col span={12}>
            <Input
              value={lorwanState.channelMode2Value}
              className='textCenter'
              onChange={(e) => handleFieldChange('channelMode2Value', e.target.value)}
            />
          </Col>
          <Col span={6}>
            <Button onClick={()=>handleDecrement('channelMode2Value',lorwanState.channelMode2Value)} className='btnChannel'>-</Button>
          </Col>
          </Row>
    </Form.Item>
  </Col>

    </Row> */}


    {/* <Row gutter={16} className='br-btm-space'>
        <Col span={8}>
          <Form.Item className='settingfield' label="">
            <Switch
              checked={lorwanState.channelMode3Switch}
              onChange={(checked) => handleFieldChange('channelMode3Switch', checked)}
            />
          </Form.Item>
        </Col>
          <Col span={16}>
 <Form.Item className='settingfield' label="">

      <Row gutter={16}>
      <Col span={6}> 
            <Button onClick={()=>handleIncrement('channelMode3Value',lorwanState.channelMode3Value)} className='btnChannel'>+</Button>
          </Col>
          <Col span={12}>
            <Input
              value={lorwanState.channelMode3Value}
              className='textCenter'
              onChange={(e) => handleFieldChange('channelMode3Value', e.target.value)}
            />
          </Col>
          <Col span={6}>
            <Button onClick={()=>handleDecrement('channelMode3Value',lorwanState.channelMode3Value)} className='btnChannel'>-</Button>
          </Col>
          </Row>
    </Form.Item>
  </Col>

    </Row> */}


    {/* <Row gutter={16} className='br-btm-space'>
        <Col span={8}>
          <Form.Item className='settingfield' label="">
            <Switch
              checked={lorwanState.channelMode4Switch}
              onChange={(checked) => handleFieldChange('channelMode4Switch', checked)}
            />
          </Form.Item>
        </Col>
          <Col span={16}>
 <Form.Item className='settingfield' label="">

      <Row gutter={16}>
      <Col span={6}> 
            <Button onClick={()=>handleIncrement('channelMode4Value',lorwanState.channelMode4Value)} className='btnChannel'>+</Button>
          </Col>
          <Col span={12}>
            <Input
              value={lorwanState.channelMode4Value}
              className='textCenter'
              onChange={(e) => handleFieldChange('channelMode4Value', e.target.value)}
            />
          </Col>
          <Col span={6}>
            <Button onClick={()=>handleDecrement('channelMode4Value',lorwanState.channelMode4Value)} className='btnChannel'>-</Button>
          </Col>
          </Row>
    </Form.Item>
  </Col>

    </Row> */}


    {/* <Row gutter={16} className='br-btm-space'>
        <Col span={8}>
          <Form.Item className='settingfield' label="">
            <Switch
              checked={lorwanState.channelMode5Switch}
              onChange={(checked) => handleFieldChange('channelMode5Switch', checked)}
            />
          </Form.Item>
        </Col>
          <Col span={16}>
 <Form.Item className='settingfield' label="">

      <Row gutter={16}>
      <Col span={6}> 
            <Button onClick={()=>handleIncrement('channelMode5Value',lorwanState.channelMode5Value)} className='btnChannel'>+</Button>
          </Col>
          <Col span={12}>
            <Input
              value={lorwanState.channelMode5Value}
              className='textCenter'
              onChange={(e) => handleFieldChange('channelMode5Value', e.target.value)}
            />
          </Col>
          <Col span={6}>
            <Button onClick={()=>handleDecrement('channelMode5Value',lorwanState.channelMode5Value)} className='btnChannel'>-</Button>
          </Col>
          </Row>
    </Form.Item>
  </Col>

    </Row> */}


    {/* <Row gutter={16} className='br-btm-space'>
        <Col span={8}>
          <Form.Item className='settingfield' label="">
            <Switch
              checked={lorwanState.channelMode6Switch}
              onChange={(checked) => handleFieldChange('channelMode6Switch', checked)}
            />
          </Form.Item>
        </Col>
          <Col span={16}>
 <Form.Item className='settingfield' label="">

      <Row gutter={16}>
      <Col span={6}> 
            <Button onClick={()=>handleIncrement('channelMode6Value',lorwanState.channelMode6Value)} className='btnChannel'>+</Button>
          </Col>
          <Col span={12}>
            <Input
              value={lorwanState.channelMode6Value}
              className='textCenter'
              onChange={(e) => handleFieldChange('channelMode6Value', e.target.value)}
            />
          </Col>
          <Col span={6}>
            <Button onClick={()=>handleDecrement('channelMode6Value',lorwanState.channelMode6Value)} className='btnChannel'>-</Button>
          </Col>
          </Row>
    </Form.Item>
  </Col>

    </Row> */}

<Row gutter={16} style={{marginTop:10}}>
        <Col span={20}><label>Confirmed Mode  <Tooltip placement="topLeft" color={'#f4f4f4'} colorText={"#373434"} fontSize={10} lineHeight={1.2} title={"A confirmed uplink message is a message where a LoRaWAN endpoint (sensor device) is requesting a LoRaWAN network to confirm the reception of its message"} >
            <Info/>
          </Tooltip></label></Col>
        <Col span={4} style={{float:"right"}}>
          <Form.Item className='settingfield' label="">
            <Switch
              checked={lorwanState.confirmed_mode==1?true:false}
              onChange={(e)=>handleFieldChange('confirmed_mode', e?1:0,'number',1)}
            />  
          </Form.Item>
        </Col>
    </Row>
{lorwanState.confirmed_mode == 1?
  <Row gutter={16} style={{marginTop:10}}>
        <Col span={24}><label>Confirmed Mode Retries <Tooltip placement="topLeft" color={'#f4f4f4'} colorText={"#373434"} fontSize={10} lineHeight={1.2} title={"Number of retries to send a message and get successful ack"} >
            <Info/>
          </Tooltip></label></Col>
        <Col span={24} style={{float:"right"}}>
          <Form.Item className='settingfield' label="">
            
            <Select
              value={lorwanState.confirmed_mode_retries}
              onChange={(e) => handleFieldChange('confirmed_mode_retries', e,'number',2)}
              showSearch
               status={props.error && props.error['confirmed_mode_retries']?"error":""}
              //  disabled={props.mode && props.mode=="disabled"?true:false}
            >
              {
              [1,2,3,4,5,6,7,8].map((el)=>{
                return <Option value={el} key={"conmfirmretry"+el}>{el}</Option> 
              }) 
              }
            </Select>
          </Form.Item>
        </Col>
    </Row>:""}
<Row gutter={16} style={{marginTop:10}}>
        <Col span={20}><label>Data Storage <Tooltip placement="topLeft" color={'#f4f4f4'} colorText={"#373434"} fontSize={10} lineHeight={1.2} title={"Storing failed messages for retransmission."} >
            <Info/>
          </Tooltip></label></Col>
        <Col span={4} style={{float:"right"}}>
          <Form.Item className='settingfield' label="">
            <Switch
              checked={lorwanState.data_storage==1?true:false}
              onChange={(e)=>handleFieldChange('data_storage', e?1:0,'number',1)}
            />  
          </Form.Item>
        </Col>
    </Row>

<Row gutter={16} style={{marginTop:10}}>
        <Col span={20}><label>Data Retransmission <Tooltip placement="topLeft" color={'#f4f4f4'} colorText={"#373434"} fontSize={10} lineHeight={1.2} title={"Retransmit failed messages if the network is available again"} >
            <Info/>
          </Tooltip></label></Col>
        <Col span={4} style={{float:"right"}}>
          <Form.Item className='settingfield' label="">
            <Switch
              checked={lorwanState.data_retransmission==1?true:false}
              onChange={(e)=>handleFieldChange('data_retransmission', e?1:0,'number',1)}
            />  
          </Form.Item>
        </Col>
    </Row>





    </Form>
  );
};

export default LorwanTab;
