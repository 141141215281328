import React, { useCallback, useContext, useEffect, useState } from 'react';
import ScannerLoader from './ScannerLoader';
import { ActionsContext } from '../../contexts/context';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Button, Modal } from 'antd';

const Scan = () => {
  const [message, setMessage] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [modalStatus, SetModalStatus] = useState(false);
  const { actions, setActions } = useContext(ActionsContext);
  const navigate = useNavigate();

  let sequnece = [
    { id: 1, key: "deviceEui", required: false, validation: "hex", length: 16 },
    { id: 2, key: "appEui", required: false, validation: "hex", length: 32 },
    { id: 3, key: "applicationKey", required: true, validation: "hex", length: 32 },
    { id: 4, key: "appPort", required: true, validation: "number", length: 3 },
    { id: 5, key: "joinType", required: true, validation: "number", length: 1 },
    { id: 6, key: "networkSessionKey", required: false, validation: "hex", length: 32 },
    { id: 7, key: "applicationSessionKey", required: false, validation: "hex", length: 32 },
    { id: 8, key: "workMode", required: true, validation: "number", length: 1 },
    { id: 9, key: "region", required: true, validation: "number", length: 1 },
    { id: 10, key: "rx2DataRate", required: true, validation: "number", length: 2 },
    { id: 11, key: "rxChannel", required: false, validation: "number", length: 2 },
    { id: 12, key: "spreadingFactor", required: true, validation: "number", length: 2 },
    { id: 13, key: "txPower", required: true, validation: "number", length: 2 },
    { id: 14, key: "adrmode", required: true, validation: "number", length: 1 },
    { id: 15, key: "reportingInterval", required: true, validation: "number", length: 2 },
    { id: 16, key: "confirmed_mode", required: true, validation: "number", length: 1 },
    { id: 17, key: "confirmed_mode_retries", required: true, validation: "number", length: 1 },
    { id: 18, key: "data_storage", required: true, validation: "number", length: 1 },
    { id: 19, key: "data_retransmission", required: true, validation: "number", length: 1 },
    { id: 20, key: "offsetTemp", required: true, validation: "numberString", length: 5 },
    { id: 21, key: "offsetHumidity", required: true, validation: "numberString", length: 3 },
    { id: 22, key: "offsetCo2", required: true, validation: "numberString", length: 5 },
    { id: 23, key: "model_number", required: false, validation: "string", length: 5 },
    { id: 24, key: "firm_ver_number", required: false, validation: "string", length: 5 },
    { id: 25, key: "lora_ver_number", required: false, validation: "string", length: 5 },
    { id: 26, key: "rssi", required: false, validation: "numberString", length: 5 },
    { id: 27, key: "sync_timestamp", required: false, validation: "number", length: 10 },
    { id: 28, key: "reading_timestamp", required: false, validation: "number", length: 10 },
    { id: 29, key: "battery", required: false, validation: "numberString", length: 2 },
    { id: 30, key: "temp", required: false, validation: "numberString", length: 5 },
    { id: 31, key: "humidity", required: false, validation: "numberString", length: 5 },
    { id: 32, key: "co2", required: false, validation: "numberString", length: 5 },
    { id: 33, key: "ssid", required: true, validation: "string", length: 35 },
    { id: 34, key: "password", required: true, validation: "string", length: 35 },
    { id: 35, key: "pm1Offset", required: false, validation: "numberString", length: 2 },
    { id: 36, key: "pm2_5Offset", required: false, validation: "numberString", length: 2 },
    { id: 37, key: "pm10Offset", required: false, validation: "numberString", length: 2 },
    { id: 38, key: "pressureOffset", required: false, validation: "numberString", length: 2 },
    { id: 39, key: "coOffset", required: false, validation: "numberString", length: 7 },
    { id: 40, key: "coRo", required: false, validation: "numberString", length: 7 },
    { id: 41, key: "no2Offset", required: false, validation: "numberString", length: 7 },
    { id: 42, key: "no2Ro", required: false, validation: "numberString", length: 7 },
    { id: 43, key: "o3Offset", required: false, validation: "numberString", length: 7 },
    { id: 44, key: "tvocOffset", required: false, validation: "numberString", length: 7 },
    { id: 45, key: "organizationId", required: false, validation: "string", length: 5 },
    { id: 46, key: "nh3Offset", required: false, validation: "numberString", length: 7 },
    { id: 47, key: "nh3Ro", required: false, validation: "numberString", length: 7 },
    { id: 48, key: "noiseOffset", required: false, validation: "numberString", length: 2 },
    { id: 49, key: "luxOffset", required: false, validation: "numberString", length: 3 },
    { id: 50, key: "occupancyOffset", required: false, validation: "numberString", length: 10 },
    { id: 51, key: "pressure", required: false, validation: "numberString", length: 4 },
    { id: 52, key: "pm1", required: false, validation: "numberString", length: 3 },
    { id: 53, key: "pm2_5", required: false, validation: "numberString", length: 3 },
    { id: 54, key: "pm10", required: false, validation: "numberString", length: 3 },
    { id: 55, key: "tvoc", required: false, validation: "numberString", length: 7 },
    { id: 56, key: "no2", required: false, validation: "numberString", length: 7 },
    { id: 57, key: "co", required: false, validation: "numberString", length: 7 },
    { id: 58, key: "o3", required: false, validation: "numberString", length: 7 },
    { id: 59, key: "nh3", required: false, validation: "numberString", length: 7 },
    { id: 60, key: "audioDba", required: false, validation: "numberString", length: 3 },
    { id: 61, key: "audioPeakDba", required: false, validation: "numberString", length: 3 },
    { id: 62, key: "occupancyStatus", required: false, validation: "string", length: 10 },
    { id: 63, key: "light", required: false, validation: "numberString", length: 5 },
    { id: 64, key: "espFwVersion", required: false, validation: "string", length: 7 },
    { id: 65, key: "communicationMode", required: true, validation: "number", length: 2 },
    { id: 66, key: "peopleCount", required: false, validation: "number", length: 2 },
  ];

    let sequneceAqm02 = [
    { id: 1, key: "deviceEui", required: false, validation: "hex", length: 16 },
    { id: 2, key: "appEui", required: false, validation: "hex", length: 32 },
    { id: 3, key: "applicationKey", required: true, validation: "hex", length: 32 },
    { id: 4, key: "appPort", required: true, validation: "number", length: 3 },
    { id: 5, key: "joinType", required: true, validation: "number", length: 1 },
    { id: 6, key: "networkSessionKey", required: false, validation: "hex", length: 32 },
    { id: 7, key: "applicationSessionKey", required: false, validation: "hex", length: 32 },
    { id: 8, key: "workMode", required: true, validation: "number", length: 1 },
    { id: 9, key: "region", required: true, validation: "number", length: 1 },
    { id: 10, key: "rx2DataRate", required: true, validation: "number", length: 2 },
    { id: 11, key: "rxChannel", required: false, validation: "number", length: 2 },
    { id: 12, key: "spreadingFactor", required: true, validation: "number", length: 2 },
    { id: 13, key: "txPower", required: true, validation: "number", length: 2 },
    { id: 14, key: "adrmode", required: true, validation: "number", length: 1 },
    { id: 15, key: "reportingInterval", required: true, validation: "number", length: 2 },
    { id: 16, key: "confirmed_mode", required: true, validation: "number", length: 1 },
    { id: 17, key: "confirmed_mode_retries", required: true, validation: "number", length: 1 },
    { id: 18, key: "data_storage", required: true, validation: "number", length: 1 },
    { id: 19, key: "data_retransmission", required: true, validation: "number", length: 1 },
    { id: 20, key: "offsetTemp", required: true, validation: "numberString", length: 5 },
    { id: 21, key: "offsetHumidity", required: true, validation: "numberString", length: 3 },
    { id: 22, key: "offsetCo2", required: true, validation: "numberString", length: 5 },
    { id: 23, key: "model_number", required: false, validation: "string", length: 5 },
    { id: 24, key: "firm_ver_number", required: false, validation: "string", length: 5 },
    { id: 25, key: "lora_ver_number", required: false, validation: "string", length: 5 },
    { id: 26, key: "rssi", required: false, validation: "numberString", length: 5 },
    { id: 27, key: "sync_timestamp", required: false, validation: "number", length: 10 },
    { id: 28, key: "reading_timestamp", required: false, validation: "number", length: 10 },
    { id: 29, key: "battery", required: false, validation: "numberString", length: 2 },
    { id: 30, key: "temp", required: false, validation: "numberString", length: 5 },
    { id: 31, key: "humidity", required: false, validation: "numberString", length: 5 },
    { id: 32, key: "co2", required: false, validation: "numberString", length: 5 },
    { id: 33, key: "calibrationStatus", required: false, validation: "number", length: 1 },
    { id: 34, key: "co2Calibration", required: false, validation: "number", length: 2 },
    { id: 35, key: "co2CalibrationValue", required: false, validation: "number", length: 4 },
  ];

  const generalState = useSelector((state) => state.lorwan);
  const scanState = useSelector((state) => state.scan);
  const resetState = useSelector((state) => state.reset);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Setting Scan", generalState);
  }, [generalState]);

  useEffect(() => {
    console.log("Status Scan", scanState);
  }, [scanState]);

  useEffect(() => {
    console.log("reset Scan", resetState);
  }, [resetState]);

  const handleNativeScanMessage = (inputString) => {
    readWriteDataHandler(inputString);

  };

  function extractSSID(str) {
    const length = parseInt(str.substring(0, 2), 10);
    const secondPart = str.split(".");
    const ssid = secondPart && secondPart[1]? secondPart[1].substring(0,length):" - ";
    return ssid.replace(/\*+$/, '');
}


  function readWriteDataHandler(inputString) {

    let arr = inputString.split(",");
    let seq=[...sequnece];
    let model= inputString.includes("23:AQM02")?"AQM02":"AQM05"

    if(inputString.includes("23:AQM02")){
      seq=[...sequneceAqm02];
    }

    console.log("sequences",seq)


    console.log("all field value", inputString);
    let allField = {}

    console.log("arr", arr)
    for (let i = 0; i < arr.length; i++) {
      let val = arr[i].split(":");
      let key = seq.find(el => el.id == val[0]);
      
      if(key){
          console.log("key",key,val[0],key.key,val[1])        
      }else{
        console.log("undefined key",val[0],val[1])

      }
      

      if(key){
        allField[key.key] = val[1];
      }
      console.log("reset val[0]",val[0],key.key,val[1])
      if (parseInt(val[0]) === 1) {
        localStorage.setItem('nfcdeviceEui', val[1] ? val[1].toUpperCase().replaceAll(":", "") : "")
        dispatch({ type: `UPDATE_RESET_deviceEui`, payload: val[1] ? val[1].toUpperCase().replaceAll(":", "") : "" })
      }

    if(parseInt(val[0]) === 23){
      console.log("reset execute 1")
      dispatch({ type: `UPDATE_RESET_model_number`, payload: val[1] })
    }
    if(parseInt(val[0]) === 24 ){
            console.log("reset execute 2")
      dispatch({ type: `UPDATE_RESET_firm_ver_number`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  25){
            console.log("reset execute 3")
      dispatch({ type: `UPDATE_RESET_lora_ver_number`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  27){
            console.log("reset execute 4")
        dispatch({ type: `UPDATE_RESET_reading_timestamp`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  26){     
      dispatch({ type: `UPDATE_RESET_rssi`, payload: val[1] })
    }
    
    if(parseInt(val[0]) ===  28){           
      dispatch({ type: `UPDATE_RESET_sync_timestamp`, payload: val[1] })
    }
    //scan senor value
    if(parseInt(val[0]) ===  32){           
      dispatch({ type: `UPDATE_RESET_co2`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  31){           
      dispatch({ type: `UPDATE_RESET_humidity`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  29){           
      dispatch({ type: `UPDATE_RESET_battery`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  30){           
      dispatch({ type: `UPDATE_RESET_temp`, payload: val[1] })
    }
    //new sensor value
    if(parseInt(val[0]) ===  28){           
      dispatch({ type: `UPDATE_RESET_sync_timestamp`, payload: val[1] })
    }

    //ssid and password
    if(parseInt(val[0]) ===  33 && model==='AQM05'){           
      dispatch({ type: `UPDATE_RESET_ssid`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  34 && model==='AQM05'){           
      dispatch({ type: `UPDATE_RESET_password`, payload: val[1] })
    }

    if(parseInt(val[0]) ===  45){           
      dispatch({ type: `UPDATE_RESET_organizationId`, payload: val[1] })
    }
    
    //scan senor value
    if(parseInt(val[0]) ===  51){         
      dispatch({ type: `UPDATE_RESET_pressure`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  52){           
      console.log("52",'UPDATE_RESET_pm1',val[1])
      dispatch({ type: `UPDATE_RESET_pm1`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  53){           
      dispatch({ type: `UPDATE_RESET_pm2_5`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  54){           
      dispatch({ type: `UPDATE_RESET_pm10`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  55){           
      dispatch({ type: `UPDATE_RESET_tvoc`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  56){           
      dispatch({ type: `UPDATE_RESET_no2`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  57){           
      dispatch({ type: `UPDATE_RESET_co`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  58){           
      dispatch({ type: `UPDATE_RESET_o3`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  59){           
      dispatch({ type: `UPDATE_RESET_nh3`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  60){           
      dispatch({ type: `UPDATE_RESET_audioDba`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  61){           
      dispatch({ type: `UPDATE_RESET_audioPeakDba`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  62){           
      dispatch({ type: `UPDATE_RESET_occupancyStatus`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  63){           
      dispatch({ type: `UPDATE_RESET_light`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  64){           
      dispatch({ type: `UPDATE_RESET_espFwVersion`, payload: val[1] })
    }
    if(parseInt(val[0]) ===  66){           

      dispatch({ type: `UPDATE_RESET_peopleCount`, payload: val[1] })
    }
  }

    // console.log("allField['deviceEui']", allField['deviceEui'],);
    
    if (allField['deviceEui'] && allField['deviceEui'] !== "") {
      allField['status'] = true;
      
      if(allField['ssid']){
        allField['ssid'] = extractSSID(allField['ssid']);
      }
      if(allField['password']){
        allField['password'] = extractSSID(allField['password']);
      }


      console.log("allField",allField,allField['password']);

      if(allField.model_number==="AQM02" || allField.model_number===""){
        dispatch({ type: `UPDATE_RESET_offsetTemp`, payload: '+00.0' });  
        dispatch({ type: `UPDATE_RESET_offsetHumidity`, payload: '+00' });  
        dispatch({ type: `UPDATE_RESET_reportingInterval`, payload: '0010' });  
      }else{
        dispatch({ type: `UPDATE_RESET_offsetTemp`, payload: '-05.0' });  
        dispatch({ type: `UPDATE_RESET_offsetHumidity`, payload: '+10' });  
        dispatch({ type: `UPDATE_RESET_reportingInterval`, payload: '0001' });  
      }

      dispatch({ type: `UPDATE_LORWAN_all`, payload: allField });
      dispatch({ type: `UPDATE_SCAN_all`, payload: allField });
      setActions({
        scan: null,
        write: null
      });
    } else {
      allField['scannedStatus'] = "Device is Faulty";
      SetModalStatus(true);
    }
    console.log("all Fielvalue",allField)
    
    setTimeout(function () {
      // navigate('/setting');
    }, 100)
  }

//   useEffect(()=>{
//   //aqm02
//   let inputString = `1:E002536670A15710,2:0000000000000000,3:20000000000000000000000000000001,4:002,5:1,6:D72C78758CDCCABF55EE4A778D16EF67,7:15B1D0EFA463DFBE3D11181E1EC7DA85,8:0,9:0,10:00,11:0,12:00,13:00,14:1,15:0010,16:1,17:8,18:0,19:0,20:+00.0,21:+00,22:+0000,23:AQM02,24:0.1.0.0,25:0.1.0.3,26:+00,27:1731586374,28:1731604876,29:100,30:27.9,31:061,32:0180,33:0,34:01,35:0400`;
  

// //  //RESET DATA AQM05
// // let inputString =`1:000594E686F7AC44,2:0000000000000000,3:20000000000000000000000000000001,4:002,5:1,6:00000000000000000000000000000000,7:00000000000000000000000000000000,8:0,9:0,10:02,11:0,12:02,13:00,14:1,15:0010,16:1,17:8,18:0,19:0,20:-04.0,21:+10,22:+0000,23:AQM05,24:0.1.0.0,25:0.1.0.3,26:+00,27:1726463948,28:0000000000,29:000,30:00.0,31:000,32:0000,33:00.********************************,34:00.********************************,35:+00,36:+00,37:+00,38:+00,39:+00.000,40:0250000,41:+00.000,42:0002200,43:-00.000,44:-00.000,45:00003,46:-00.000,47:0100000,48:+00,49:+000,50:0000000000,51:0000,52:000,53:000,54:000,55:000.000,56:000.000,57:000.000,58:000.000,59:000.000,60:000,61:000,62:0000000000,63:00000,64:0.0.0.0,65:01`;

// //SETTING MODIFICATION AQM05
// // let inputString =`1:000594E686F8A968,2:0000000000000000,3:20000000000000000000000000000001,4:002,5:1,6:D72C78758CDCCABF55EE4A778D16EF67,7:15B1D0EFA463DFBE3D11181E1EC7DA85,8:0,9:0,10:00,11:0,12:00,13:00,14:1,15:0001,16:1,17:8,18:0,19:0,20:-15.0,21:+20,22:+0004,23:AQM05,24:0.1.0.0,25:0.1.0.3,26:+23,27:1726554639,28:0000000275,29:100,30:40.5,31:045,32:0404,33:10.ZTE-HXdDhS**********************,34:08.d55p4xb4************************,35:+05,36:+03,37:+02,38:+03,39:+00.400,40:0251000,41:+00.050,42:0002300,43:+00.000,44:+00.000,45:00003,46:+00.300,47:0101000,48:+01,49:+004,50:0000000007,51:0003,52:027,53:043,54:044,55:000.560,56:001.520,57:023.360,58:000.000,59:003.870,60:065,61:087,62:0000000001,63:00030,64:0.1.3.2,65:01,66:10`;
// // let inputString =`1:000594E686F7A388,2:0000000000000000,3:20000000000000000000000000000001,4:002,5:1,6:00000000000000000000000000000000,7:00000000000000000000000000000000,8:0,9:0,10:02,11:0,12:02,13:00,14:1,15:0010,16:1,17:8,18:0,19:0,20:+05.0,21:+10,22:+0000,23:AQM05,24:0.1.0.0,25:0.1.0.3,26:+00,27:1732871069,28:1732789559,29:100,30:19.4,31:032,32:0406,33:13.Airtel_CHERRY*******************,34:09.Berry@123***********************,35:+00,36:+00,37:+00,38:+00,39:+00.000,40:0400000,41:+00.000,42:0002200,43:+00.000,44:+00.000,45:00003,46:+00.000,47:0600000,48:+00,49:+000,50:0000000000,51:0942,52:051,53:090,54:100,55:000.090,56:000.660,57:002.710,58:000.010,59:023.070,60:058,61:064,62:0000000002,63:00003,64:0.1.4.0,65:02,66:03`;

// setTimeout(() => {
//     readWriteDataHandler(inputString);
// }, 1000);
//   },[])



  useEffect(() => {
    // Set up the message event listener to handle messages from the native side
    const handleMessage = (event) => {
    
      
      const message = event.data;
      
      if (message && message.type === 'nfcReadSuccess') {
        handleNativeScanMessage(message.data); 
      }
      if (message && message.type === 'nfcScanCancelled') {
         setActions({
              scan: null,
              write: null
            })
      }

      if(message && message.type === 'deviceType'){
        document.body.classlist.add('iosdevice')
      }
      
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };


  }, []);

  return (
    <>
      <ScannerLoader status={actions.scan}></ScannerLoader>
      <Modal
        title=""
        open={modalStatus}
        onCancel={() => SetModalStatus(false)}
        footer={[
          <Button key="close" onClick={() => {
            SetModalStatus(false);
            setActions({
              scan: null,
              write: null
            })
          }}>
            Close
          </Button>,
        ]}
      >
        <h4>Device Configuration Corrupted</h4>
      </Modal>
    </>
  );
};

export default Scan;
