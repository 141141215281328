import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  Row} from 'antd';
import OffsetField from './components/OffsetField';

const OffsetSetting = (props) => {



  return (
    
    <Row gutter={16}>
      <OffsetField
        label="Temperature"
        field="offsetTemp"
        resolution={0.1}
        range={[-99, 99]}
        format={'+00.0'}
        charLength={3}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="Humidity"
        field="offsetHumidity"
        resolution={1}
        range={[-99, 99]}
        format={'+00'}
        charLength={3}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="CO2"
        field="offsetCo2"
        resolution={1}
        range={[-9999, 9999]} // Assuming CO2 ranges to higher values
        charLength={5}
        format={'+9999'}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="PM 1.0"
        field="pm1Offset"
        resolution={1}
        range={[-99, 99]}
        format={'+99'}
        charLength={3}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="PM 2.5"
        field="pm2_5Offset"
        resolution={1}
        range={[-99, 99]}
        format={'+99'}
        charLength={3}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="PM 10.0"
        field="pm10Offset"
        resolution={1}
        range={[-99, 99]}
        format={'+00'}
        charLength={3}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="Pressure"
        field="pressureOffset"
        resolution={1}
        range={[-99, 99]}
        format={'+00'}
        charLength={3}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="CO"
        field="coOffset"
        resolution={0.1}
        range={[-99.999, 99.999]}
        format={'+00.000'}
        charLength={7}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="NO2"
        field="no2Offset"
        resolution={0.01}
        range={[-99.999, 99.999]}
        format={'+00.000'}
        charLength={7}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="O3"
        field="o3Offset"
        resolution={0.01}
        range={[-99.999, 99.999]}
        format={'+00.000'}
        charLength={7}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="TVOC"
        field="tvocOffset"
        resolution={0.01}
        range={[-99.999, 99.999]}
        format={'+00.000'}
        charLength={7}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="NH3"
        field="nh3Offset"
        resolution={0.1}
        range={[-99.999, 99.999]}
        format={'+00.000'}
        charLength={7}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="Ambient Noise"
        field="noiseOffset"
        resolution={1}
        range={[-99, 99]}
        format={'+00'}
        charLength={2}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="Ambient Light"
        field="luxOffset"
        resolution={1}
        range={[-999, 999]}
        format={'+000'}
        charLength={3}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="Occupancy"
        field="occupancyOffset"
        resolution={1}
        range={[0, 9999999999]} // Placeholder range, as actual range is yet to be determined
        format={'0000000000'}
        charLength={10}   // Placeholder length
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />

    </Row>
  );
};

export default OffsetSetting;
