const initialState = {
  deviceEui: "",
  appEui: '',
  appPort: "",
  joinType: '',
  networkSessionKey: '',
  applicationSessionKey: '',
  applicationKey: '',
  rx2DataRate: '',
  rx2Frequency: '',
  spreadingFactor: '',
  txPower: '',
  workMode: "",
  region: "",
  rxChannel: "",
  reportingInterval: "",
  offsetTemp: "",
  offsetHumidity: "",
  offsetCo2: "",
  status: false,
  scannedStatus: "",
  co2: null,
  humidity: null,
  temp: null,
  deviceType: "",
  battery: "",
  adrmode: "",
  confirmed_mode: "",
  confirmed_mode_retries: "",
  data_storage: "",
  data_retransmission: "",
  model_number: "",
  firm_ver_number: "",
  lora_ver_number: "",
  rssi: "",
  sync_timestamp: "",
  reading_timestamp: "",
  ssid: "",
  password: "",
  pm1Offset: "",
  pm2_5Offset: "",
  pm10Offset: "",
  pressureOffset: "",
  coOffset: "",
  coRo: "",
  no2Offset: "",
  no2Ro: "",
  o3Offset: "",
  tvocOffset: "",
  organizationId: "",
  nh3Offset: "",
  nh3Ro: "",
  noiseOffset: "",
  luxOffset: "",
  occupancyOffset: "",
  pressure: "",
  pm1: "",
  pm2_5: "",
  pm10: "",
  tvoc: "",
  no2: "",
  co: "",
  o3: "",
  nh3: "",
  audioDba: "",
  audioPeakDba: "",
  occupancyStatus: "",
  light: "",
  espFwVersion: "",
  communicationMode: "",
  peopleCount:"",
  calibrationStatus:"",
  co2Calibration:"",
  co2CalibrationValue:"" 
};

const scanReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'UPDATE_SCAN_all':
      return { ...state,...action.payload };
    case 'UPDATE_SCAN_deviceEui':
      return { ...state, deviceEui: action.payload }; 
    case 'UPDATE_SCAN_status':
      return { ...state, status: action.payload }; 
    case 'UPDATE_SCAN_adrmode':
        return { ...state,adrmode:action.payload };        
    case 'UPDATE_SCAN_scannedStatus':
        return { ...state,scannedStatus:action.payload };        

        //Aqm 05
    case 'UPDATE_SCAN_ssid':
      return { ...state, ssid: action.payload };
    case 'UPDATE_SCAN_password':
      return { ...state, password: action.payload };
    case 'UPDATE_SCAN_pm1Offset':
      return { ...state, pm1Offset: action.payload };
    case 'UPDATE_SCAN_pm2_5Offset':
      return { ...state, pm2_5Offset: action.payload };
    case 'UPDATE_SCAN_pm10Offset':
      return { ...state, pm10Offset: action.payload };
    case 'UPDATE_SCAN_pressureOffset':
      return { ...state, pressureOffset: action.payload };
    case 'UPDATE_SCAN_coOffset':
      return { ...state, coOffset: action.payload };
    case 'UPDATE_SCAN_coRo':
      return { ...state, coRo: action.payload };
    case 'UPDATE_SCAN_no2Offset':
      return { ...state, no2Offset: action.payload };
    case 'UPDATE_SCAN_no2Ro':
      return { ...state, no2Ro: action.payload };
    case 'UPDATE_SCAN_o3Offset':
      return { ...state, o3Offset: action.payload };
    case 'UPDATE_SCAN_tvocOffset':
      return { ...state, tvocOffset: action.payload };
    case 'UPDATE_SCAN_organizationId':
      return { ...state, organizationId: action.payload };
    case 'UPDATE_SCAN_nh3Offset':
      return { ...state, nh3Offset: action.payload };
    case 'UPDATE_SCAN_nh3Ro':
      return { ...state, nh3Ro: action.payload };
    case 'UPDATE_SCAN_noiseOffset':
      return { ...state, noiseOffset: action.payload };
    case 'UPDATE_SCAN_luxOffset':
      return { ...state, luxOffset: action.payload };
    case 'UPDATE_SCAN_occupancyOffset':
      return { ...state, occupancyOffset: action.payload };
    case 'UPDATE_SCAN_pressure':
      return { ...state, pressure: action.payload };
    case 'UPDATE_SCAN_pm1':
      return { ...state, pm1: action.payload };
    case 'UPDATE_SCAN_pm2_5':
      return { ...state, pm2_5: action.payload };
    case 'UPDATE_SCAN_pm10':
      return { ...state, pm10: action.payload };
    case 'UPDATE_SCAN_tvoc':
      return { ...state, tvoc: action.payload };
    case 'UPDATE_SCAN_no2':
      return { ...state, no2: action.payload };
    case 'UPDATE_SCAN_co':
      return { ...state, co: action.payload };
    case 'UPDATE_SCAN_o3':
      return { ...state, o3: action.payload };
    case 'UPDATE_SCAN_nh3':
      return { ...state, nh3: action.payload };
    case 'UPDATE_SCAN_audioDba':
      return { ...state, audioDba: action.payload };
    case 'UPDATE_SCAN_audioPeakDba':
      return { ...state, audioPeakDba: action.payload };
    case 'UPDATE_SCAN_occupancyStatus':
      return { ...state, occupancyStatus: action.payload };
    case 'UPDATE_SCAN_light':
      return { ...state, light: action.payload };
    case 'UPDATE_SCAN_espFwVersion':
      return { ...state, espFwVersion: action.payload };
    case 'UPDATE_SCAN_communicationMode':
      return { ...state, communicationMode: action.payload };    
    case 'UPDATE_SCAN_peopleCount':
      return { ...state, peopleCount: action.payload };    
    case 'UPDATE_SCAN_co2Calibration':
      return { ...state, co2Calibration: action.payload };
    case 'UPDATE_SCAN_co2CalibrationValue':
      return { ...state, co2CalibrationValue: action.payload };  
    case 'UPDATE_SCAN_calibrationStatus':
      return { ...state, calibrationStatus: action.payload };    

    default:
      return state;
  }
};

export default scanReducer;
