
import Scan from './Scan';
import WriteLanding from './Writer/index';
import { useEffect, useState } from 'react';
import { ActionsContext } from '../../contexts/context';
import AbStractNFC from '../../assets/images/absract.png'
import { useNavigate } from 'react-router-dom';
import { Row , Col, Tooltip,Collapse } from 'antd';
import TemperatureIcon from '../../assets/images/temperatureCircle.png'
import HumidityIcon from '../../assets/images/humidityCircle.png'
import CO2Icon from '../../assets/images/co2Circle.png'
import { useSelector, useDispatch } from 'react-redux';
import {ReactComponent as Info} from  '../../assets/images/circle-info-solid.svg'
import { PlusOutlined,MinusOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

function StatusLanding(props) {
  const lorwanState = useSelector((state) => state.scan);
  const settingState = useSelector((state) => state.lorwan);
  const resetState = useSelector((state) => state.reset);
  const dispatch = useDispatch();
  const joinType=["ABP","OTAA"];
  const workMode =["Class A","Class B","Class C"];
  const region=  ["AS923","AU915","CN470","CN799","EU433","EU868","IN865", "KR920","US915"]
  // const txPower= ["8dBm","10dBm", "12dBm","14dBm","16dBm","22dBm"]
  const rx2Data= {
    "0":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
    "1":[
          {id:"00",spread:"SF10 - DR0",name:"SF10 - BW125" },
          {id:"01",spread:"SF9 - DR1",name:"SF9  - BW125" },
          {id:"02",spread:"SF8 - DR2",name:"SF8  - BW125" },
          {id:"03",spread:"SF7 - DR3",name:"SF7  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW500" },
          {id:"08",spread:"SF12 - DR8",name:"SF12 - BW500" },
          {id:"09",spread:"SF11 - DR9",name:"SF11 - BW500" },
          {id:"10",spread:"SF10 - DR10",name:"SF10 - BW500" },
          {id:"11",spread:"SF9 - DR11",name:"SF9 - BW500" },
          {id:"12",spread:"SF8 - DR12",name:"SF8 - BW500" },
          {id:"13",spread:"SF7 - DR13",name:"SF7 - BW500" },
          
        ],
    "2":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF12 - DR6",name:"SF12 - BW125" },
          {id:"07",spread:"SF12 - DR7",name:"SF12 - BW125" },
        ],
      "3":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "4":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "5":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "6":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "7":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9  - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8  - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7  - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7  - DR6",name:"SF7  - BW250" },
      ],
        "8":[
          {id:"00",spread:"SF10 - DR0",name:"SF10 - BW125"},
          {id:"01",spread:"SF9 - DR1",name:"SF9  - BW125"},
          {id:"02",spread:"SF8 - DR2",name:"SF8  - BW125"},
          {id:"03",spread:"SF7 - DR3",name:"SF7  - BW125"},
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW500"},
          {id:"08",spread:"SF12- DR8",name:"SF12 - BW500"},
          {id:"09",spread:"SF11- DR9",name:"SF11 - BW500"},
          {id:"10",spread:"SF10- DR10",name:"SF10 - BW500"},
          {id:"11",spread:"SF9 - DR11",name:"SF9 - BW500"},
          {id:"12",spread:"SF8 - DR12",name:"SF8 - BW500"},
          {id:"13",spread:"SF7 - DR13",name:"SF7 - BW500"},
        ],
  }

  const calibrationType ={'01':'Background Calibration','02':'Target Calibration'}
  function extractSSID(str) {
  console.log("lorwanState.ssid",lorwanState.ssid)
  const length = parseInt(str.substring(0, 2), 10);
  const secondPart = str.split(".");
  const ssid = secondPart && secondPart[1]? secondPart[1].substring(0,length):" - ";
  return ssid.replace(/\*+$/, '');
}
  useEffect(()=>{
    console.log("Setting Scan",settingState);
  },[settingState])

    useEffect(()=>{
    console.log("Status Scan",lorwanState);
  },[lorwanState])

useEffect(()=>{
    console.log("reset Scan",resetState);
  },[resetState])

 const txPower={
    "0":[
      {id:"00",name:"TXPower0-16 dBm"},
      {id:"01",name:"TXPowerl-14 dBm"},
      {id:"02",name:"TXPower2-12 dBm"},
      {id:"03",name:"TXPower3-1 0 dBm"},
      {id:"04",name:"TXPower4-8 dBm"},
      {id:"05",name:"TXPower5-6 dBm" },
      {id:"06",name:"TXPower6-4 dBm"}, 
      {id:"07",name:"TXPower7-2 dBm"},
    ],
    "6":[
      {id:"00",name:"TXPowerO-22dBm"},
      {id:"01",name:"TXPowerl-22 dBm"},
      {id:"02",name:"TXPower2-22 dBm"},
      {id:"03",name:"TXPower3-22 dBm"},
      {id:"04",name:"TXPower4-22 dBm"},
      {id:"05",name:"TXPower5-20 dBm" },
      {id:"06",name:"TXPower6-18 dBm"}, 
      {id:"07",name:"TXPower7-16 dBm"},
      {id:"08",name:"TXPower8-14 dBm"},
      {id:"09",name:"TXPower9-12dBm"},
      {id:"10",name:"TXPower10-10 dBm"}
    ]
}

  return (
    <div className='StatusArea' style={{marginTop:15}}>
        <div className='container'>
            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24}><h4>Last Measured <span style={{float:"right",color:"#8a8686",fontSize:12}}>{lorwanState.reading_timestamp?new Date(lorwanState.reading_timestamp * 1000).toLocaleString() :""}</span></h4></Col>
            </Row>
            <Row gutter={16} className='sensorList bg-white'>
                <Col span={8}>
                    <div className='sensorArea'>
                        <img src={TemperatureIcon} alt={"temperatureicon"}/>
                        <div className='sensorValue'>{lorwanState.temp &&( lorwanState.temp!=="" && lorwanState.temp!=="null"&& lorwanState.temp!==null )?lorwanState.temp:"-"}°C</div>
                        <small>Temperature</small>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='sensorArea'>
                        <img src={HumidityIcon} alt={"humidicon"}/>
                        <div className='sensorValue'>{lorwanState.humidity  && ( lorwanState.humidity!=="" && lorwanState.humidity!=="null"&& lorwanState.humidity!==null )?lorwanState.humidity:"-"}% Rh</div>
                        <small>Humidity</small>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='sensorArea'>
                        <img src={CO2Icon} alt={"co2icon"}/>
                        <div className='sensorValue'>{lorwanState.co2 && ( lorwanState.co2!=="" && lorwanState.co2!=="null"&& lorwanState.co2!==null )?lorwanState.co2:"-"} PPM</div>
                        <small>C02</small>
                    </div>
                </Col>
            </Row>
            <Row style={{minHeight:20}}></Row>
            <Row gutter={16} className='bg-white bg-white-header smallerFontDiv'> 
                <Col span={5}><h4>Model</h4></Col>
                <Col span={8}><h4>Signal Strength <Tooltip placement="topLeft" color={'#f4f4f4'} colorText={"#373434"} fontSize={10} lineHeight={1.2} title={
                  <div>
                    <p>Signal Strength {lorwanState.rssi}</p>
                    <p>{`Less than 5 Weak , possible NW disconnections & packet loss`}</p>
	  <p>{`Between 5 to 10 Good , Occasional packet loss `}</p>
	 <p>{`Above 10 Very Good , No packet loss`}</p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </div>
                } >
            <span style={{width:15,height:15,display:"inline-block"}}><Info/></span>
          </Tooltip></h4></Col>
                <Col span={6}><h4>FW Ver.</h4></Col>
                <Col span={5}><h4>Battery</h4></Col>
            </Row>
             <Row gutter={16} className='bg-white smallerFontDiv'>
                <Col span={5} className='extraSpaceTitle'><p>{lorwanState.model_number?lorwanState.model_number:"-"}</p></Col>
                <Col span={8} className='extraSpaceTitle'><p>{lorwanState.rssi?parseInt(lorwanState.rssi) <= 5?<div className='networkText networkWeak'>Weak</div>: 
                parseInt(lorwanState.rssi) <= 10?<div className='networkText networkGood'>Good</div>:<div className='networkText networkVeryGood'>Very Good</div>:"-"}</p></Col>
                <Col span={6} className='extraSpaceTitle'><p>{lorwanState.firm_ver_number?lorwanState.firm_ver_number:"-"}</p></Col>
                <Col span={5} className='extraSpaceTitle'><p>{lorwanState.battery?parseInt(lorwanState.battery)+"%":"-"}</p></Col>
             </Row>

            <Row style={{minHeight:20}}></Row>
            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24}><h4>DevEUI</h4></Col>
            </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={24} className='extraSpaceTitle'><p>{lorwanState.deviceEui?lorwanState.deviceEui:"-"}</p></Col>
             </Row>

            <Row style={{minHeight:20}}></Row>
            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24}><h4>Last Sync Time</h4></Col>
            </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={24} className='extraSpaceTitle'><p>{lorwanState.sync_timestamp?new Date(lorwanState.sync_timestamp * 1000).toLocaleString()  :"-"}</p></Col>
             </Row>


            <Row style={{minHeight:20}}></Row>
            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24}><h4>LoRaWan Ver.</h4></Col>
            </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={24} className='extraSpaceTitle'><p>{ lorwanState.lora_ver_number?lorwanState.lora_ver_number:"-"}</p></Col>
             </Row>
             


            <Row style={{minHeight:20}}></Row>
            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24}><h4>LoRaWAN Configuration</h4></Col>
            </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>App Port</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.appPort?parseInt(lorwanState.appPort):"-"}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Application Key</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold' style={{wordWrap:"break-word"}}>{lorwanState.applicationKey?lorwanState.applicationKey:"-"}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Join Type</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.joinType?joinType[lorwanState.joinType]?joinType[lorwanState.joinType]:"-":"-"}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Work Mode</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.workMode?workMode[lorwanState.workMode]?workMode[lorwanState.workMode]:"-":"-"}</p></Col>
             </Row>

            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Region</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.region?region[lorwanState.region]?region[lorwanState.region]:"-":"-"}</p></Col>
             </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>RX2 Data Rate</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.rx2DataRate?rx2Data[lorwanState.region].find(el=>el.id == lorwanState.rx2DataRate )?rx2Data[lorwanState.region].find(el=>el.id == lorwanState.rx2DataRate )?.name:"-":"-"}</p></Col>
             </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Spreading Factor</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.spreadingFactor?rx2Data[lorwanState.region].find(el=>el.id == lorwanState.spreadingFactor )?rx2Data[lorwanState.region].find(el=>el.id == lorwanState.spreadingFactor )?.spread:"-":"-"}</p></Col>
             </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Tx Power</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.txPower?txPower[lorwanState.region].find(el=>el.id===lorwanState.txPower)?txPower[lorwanState.region].find(el=>el.id===lorwanState.txPower)?.name:"-":"-"}</p></Col>
             </Row>
              
             {lorwanState.adrmode?
              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>ADR Mode</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.adrmode && lorwanState.adrmode==1?"Enabled":"Disabled"}</p></Col>
             </Row>:""}
              {lorwanState.confirmed_mode?
              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Confirmed Mode</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.confirmed_mode && lorwanState.confirmed_mode==1?"Enabled":"Disabled"}</p></Col>
             </Row>:""}
             {lorwanState.confirmed_mode_retries && lorwanState.confirmed_mode && lorwanState.confirmed_mode==1?
              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Confirmed Mode Retries</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.confirmed_mode_retries && lorwanState.confirmed_mode?lorwanState.confirmed_mode_retries:"-"}</p></Col>
             </Row>:""}
              {lorwanState.data_storage?
              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Data Storage</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.data_storage && lorwanState.data_storage==1?"Enabled":"Disabled"}</p></Col>
             </Row>:""}


            {lorwanState.data_retransmission?
              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Data Retransmission</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.data_retransmission && lorwanState.data_retransmission==1?"Enabled":"Disabled"}</p></Col>
             </Row>:""}


            <Row style={{minHeight:20}}></Row>
            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24}><h4>General Configuration</h4></Col>
            </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Reporting Interval</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.reportingInterval?parseInt(lorwanState.reportingInterval)+" min":" - "}</p></Col>
             </Row>
        
            <Row style={{minHeight:20}}></Row>
            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24}><h4>Sensor Reading Offset</h4></Col>
            </Row>

            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Temperature</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.offsetTemp?lorwanState.offsetTemp+" °C":" - "} </p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Humidity</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.offsetHumidity?lorwanState.offsetHumidity+" % Rh":" - "}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>C02</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.offsetCo2?lorwanState.offsetCo2+" PPM":" - "}</p></Col>
             </Row>

            <Row style={{minHeight:20}}></Row>
              <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24}><h4>Sensor Calibration</h4></Col>
              </Row>

<Collapse accordion defaultActiveKey={"co2_cal"}
        expandIcon={({ isActive }) => isActive?<MinusOutlined />:<PlusOutlined />}
        className='accordionSmaller'
      >
<Panel header="Co2 Calibration" key="co2_cal">
              <Row gutter={16} className='bg-white'>
                 <Col span={12} className='extraSpaceTitle'><p>Calibration Type</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.co2Calibration?calibrationType[lorwanState.co2Calibration]:" - "} </p></Col>
          </Row>
         {lorwanState.co2Calibration==="02"? <Row gutter={16} className='bg-white'>
                 <Col span={12} className='extraSpaceTitle'><p>Calibration Value</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold'>{lorwanState.co2CalibrationValue?lorwanState.co2CalibrationValue:" - "} </p></Col>
          </Row>:""}
        </Panel>
      
</Collapse>
 

            <Row style={{minHeight:20}}></Row>
        </div>
    </div>

  );
}

export default StatusLanding;
